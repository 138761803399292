import {enableProdMode, ViewEncapsulation} from '@angular/core';
import {platformBrowserDynamic}            from '@angular/platform-browser-dynamic';
import {AppModule}                         from './app.module';
import {Config} from "@synisys/idm-ui-shell/app/config";
import 'zone.js/dist/zone';

if (process.env.NODE_ENV === 'production') {
	// Production
	enableProdMode();
} else {
	// Development and test
	Error['stackTraceLimit'] = Infinity;
	require('zone.js/dist/long-stack-trace-zone');
}

// enableProdMode();
platformBrowserDynamic(
	[
		{provide: 'imagesPath', useValue: 'images'},
		{provide: 'configPath', useValue: './app/config'},
		{provide: Config.coreConfigPathToken, useValue: './app/config'}
	]
).bootstrapModule(
	AppModule,
	[{defaultEncapsulation: ViewEncapsulation.None}]
);
