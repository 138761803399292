import {Component, OnDestroy, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ApplicationPropertiesService} from "@synisys/idm-application-properties-service-client-js";
declare let gtag: Function;
@Component({
	selector: 'sis-app',
	template: require('./app.component.html')
})
export class AppComponent implements OnInit, OnDestroy{
	private readonly _destroy$ = new Subject<void>();
	constructor(public readonly router: Router, private readonly applicationPropertiesService: ApplicationPropertiesService) {
	}
	public ngOnDestroy(): void {
		this._destroy$.next();
		this._destroy$.complete();
	}
	public ngOnInit(): void {
		this.configureGA();
	}
	private configureGA(): void {
		this.applicationPropertiesService.loadProjectConfigs()
			.then(configs => {
				if (!!configs['ga']) {
					AppComponent.addGAScript(configs['ga']);
					this.router.events
						.pipe(takeUntil(this._destroy$))
						.subscribe(event => {
								if (event instanceof NavigationEnd) {
									gtag('event', 'page_view', {
										page_path: event.urlAfterRedirects
									});
								}
							}
						);
				}
			});
	}
	private static addGAScript(gaTrackingId: string) {
		const gtagScript: HTMLScriptElement = document.createElement('script');
		gtagScript.async = true;
		gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=' + gaTrackingId;
		document.head.appendChild(gtagScript);
		/** Disable automatic page view hit to fix duplicate page view count  **/
		gtag('config', gaTrackingId, {send_page_view: false});
	}
}

