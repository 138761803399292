import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CurrentLanguageProvider, SessionDataProvider} from '@synisys/idm-session-data-provider-api-js';
import {
	CurrentLanguageManager,
	LoginGuard,
	MainOnlineProvidersModule,
	SessionDataProviderImpl,
    UserSessionIdentityIntercepter
} from '@synisys/idm-ui-shell';
import {LoginPagePreventionGuard} from '@synisys/idm-ui-shell/app/services/login-page-prevention-guard';
import {AppComponent} from './app.component';
import {FlashMessagesService} from "angular2-flash-messages";
import {appRoute} from './app.routing';
import {HttpUserService, UserService} from '@synisys/idm-um-client-js';
import {AuthTokenInterceptor, UnauthorizedInterceptor} from "ngx-cookie-service";
import {AsyncLocalStorageModule} from "angular-async-local-storage";

@NgModule({
	imports: [
		BrowserAnimationsModule,
		HttpClientModule,
		appRoute,
		MainOnlineProvidersModule,
		AsyncLocalStorageModule
	],
	declarations: [
		AppComponent
	],
	providers: [
		LoginPagePreventionGuard,
		LoginGuard,
		SessionDataProviderImpl,
		CurrentLanguageManager,
		FlashMessagesService,
		{provide: UserService, useClass: HttpUserService},
		{provide: CurrentLanguageProvider, useExisting: SessionDataProviderImpl},
		{provide: SessionDataProvider, useExisting: SessionDataProviderImpl},
		{provide: HTTP_INTERCEPTORS, useExisting: UnauthorizedInterceptor, multi: true},
		{provide: HTTP_INTERCEPTORS, useExisting: AuthTokenInterceptor, multi: true},
		{provide: HTTP_INTERCEPTORS, useExisting: UserSessionIdentityIntercepter, multi: true},
		// {provide: HTTP_INTERCEPTORS, useClass: EntityAuditInterceptor, multi: true}, to take from zwe


],
	bootstrap: [AppComponent],
})
export class AppModule {
}
